<template>
  <div id="wang-editor"></div>
</template>

<script>
// 引入 wangEditor

import wangEditor from 'wangeditor'
import { uploadFile } from '@/api/api_public.js'
export default {
  data() {
    return {
      editor: null
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: function(value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    }
  },
  mounted() {
    this.seteditor()
    this.editor.txt.html(this.value)
  },
  methods: {
    seteditor() {
      const editor = new wangEditor(`#wang-editor`)
      editor.config.pasteFilterStyle = true
      editor.config.placeholder = '请输入协议内容'
      editor.config.menus = [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        // "quote",
        // "emoticon",
        'image',
        'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo'
      ]
      editor.config.colors = ['#000000', '#333333', '#666666', '#999999', '#cccccc', '#ffffff', '#ff0000', '#3d7eff']
      editor.config.fontSizes = {
        'x-small': { name: '12px', value: '1' },
        small: { name: '14px', value: '2' },
        normal: { name: '16px', value: '3' },
        large: { name: '18px', value: '4' },
        'x-large': { name: '20px', value: '5' },
        'xx-large': { name: '24px', value: '6' },
        'xxx-large': { name: '32px', value: '7' }
      }
      // 配置行高
      editor.config.lineHeights = ['1', '1.2', '1.4', '1.6', '1.8', '2', '2.4', '2.6', '3']
      editor.config.onchange = newHtml => {
        this.$emit('change', newHtml) // 将内容同步到父组件中
      }
      editor.config.uploadImgMaxLength = 1
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
      editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        // 上传图片，返回结果，将图片插入到编辑器中
        this.handleUpload(resultFiles).then(res => {
          insertImgFn(res)
        })
      }
      editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
        // 上传视频，返回结果，将视频地址插入到编辑器中
        this.handleUpload(resultFiles).then(res => {
          insertVideoFn(res)
        })
      }
      // 创建编辑器
      editor.create()
      this.editor = editor
    },
    async handleUpload(resultFiles) {
      let file = resultFiles?.[0]
      const formdata = new FormData()
      formdata.append('files', file)
      formdata.append('business', 'banner')
      let res = await uploadFile(formdata)
      if (res && res.code === '000000') {
        return res.data.list[0]
      }
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>
