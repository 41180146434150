import * as http from '../http'

export default {
  //规则说明列表
  ruleDescList: params => {
    return http.post('/agreement/pageList', params)
  },
  //规则说明新增
  ruleDescInsert: params => {
    return http.post('/agreement/add', params)
  },
  //规则说明修改
  ruleDescUpdate: params => {
    return http.post('/agreement/edit', params)
  },
  //规则说明删除
  ruleDescDelete: params => {
    return http.post('/agreement/delete', params)
  }
}
